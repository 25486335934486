import { Box, Typography } from '@mui/material';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CloseIcon from '../../../components/Icons/CloseIcon';
import UploadIcon from '../../../components/Icons/UploadIcon';
import { BulkImportMaxFileSize } from '../../../configs/PortalConfig';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import useImportFileStep from './useImportFileStep';

export interface ImportFileStepProps {
  onClose: () => void;
}

function ImportFileStep({ onClose }: ImportFileStepProps) {
  const [t] = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const snackBar = useSnackBar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isLoading, uploadFile } = useImportFileStep();

  /**
   * Simulates the click on the hidden input by clicking on the displayed button
   */
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  /**
   * Removes the selected file
   */
  const removeFile = () => {
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  /**
   * Upload the selected csv file
   */
  const handleFileUpload = () => {
    if (file) {
      uploadFile(file, onClose);
    }
  };

  /**
   * Handles file change
   * @param e
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    if (e.target.files[0].size > BulkImportMaxFileSize * 1000000) {
      snackBar.showSnackBar(t('common.inputValidations.maxSize', { val: `${BulkImportMaxFileSize}mb` }), 'error');
      removeFile();
      return;
    }
    setFile(e.target.files[0]);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="body1" lineHeight="normal">
        {t('pages.import.importDrawer.uploadDescription')}
      </Typography>
      <Typography
        sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.disabled, paddingTop: '1.5rem' }}
        lineHeight="normal"
      >
        {t('pages.import.uploadHelper', { size: BulkImportMaxFileSize })}
      </Typography>
      {/* File upload */}
      <Button
        onClick={handleUploadClick}
        color="secondaryButton"
        label={
          file ? (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {file.name}
              <CloseIcon
                stroke="#fff"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFile();
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <UploadIcon />
              {t('pages.import.uploadCsvFile')}
            </Box>
          )
        }
        sx={{ margin: '2.5rem 0 2rem 0', width: '100%' }}
      />
      <input type="file" ref={inputRef} accept=".csv" onChange={handleFileChange} style={{ display: 'none' }} />

      {/* Buttons */}
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} disabled={isLoading} />
          <Button
            label={t('common.submit')}
            color="primaryButton"
            onClick={handleFileUpload}
            disabled={!file?.name || isLoading}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default ImportFileStep;
