import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sim, SimStatus } from '../../models/UserDevicesModels';

function useSimStatus(sim: Sim) {
  const [t, i18n] = useTranslation();

  /**
   * A message to be displayed as tooltip is
   * created based on the journey and sim status
   */
  const simStatusTooltipAdapter = () => {
    let translationKey;
    if (sim.status === SimStatus.SUSPENDED) {
      translationKey = `pages.userDetails.tabs.simsTable.simJourneys.${SimStatus.SUSPENDED}_${sim.journey}`;
    } else {
      translationKey = `pages.userDetails.tabs.simsTable.simJourneys.${sim.journey}`;
    }
    return i18n.exists(translationKey) ? t(translationKey) : null;
  };

  const tooltipMessage = useMemo(() => simStatusTooltipAdapter(), [sim]);

  return { tooltipMessage };
}

export default useSimStatus;
