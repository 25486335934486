import { PortalLanguages } from '../configs/PortalConfig';

export enum RoutePermissionType {
  Dashboard = 'dashboard',
  AuditTrail = 'auditTrail',
  DevicesReport = 'devicesReport',
  ESimManager = 'eSimManager',
  UemList = 'uemList',
  UemCreate = 'uemCreate',
  CompaniesList = 'companiesList',
  CompaniesCreate = 'companiesCreate',
  CompaniesEdit = 'companiesEdit',
  AdminsList = 'adminsList',
  AdminsCreate = 'adminsCreate',
  Import = 'import',
  NewConnection = 'newConnection',
  EditDevices = 'editDevices',
}

export enum FeaturePermissionType {
  SelectCompanyAuditTrail = 'selectCompanyAuditTrail',
  SelectCountriesAuditTrail = 'selectCountriesAuditTrail',
  UserDevicesActions = 'userDevicesActions',
}

export interface Role {
  role: string;
  translations: {
    [key in PortalLanguages]: string;
  };
}

export interface PortalConfig {
  featurePermissions: {
    [key in FeaturePermissionType]: string[];
  };
  routePermissions: {
    [key in RoutePermissionType]: string[];
  };
  roles: Role[];
}

export interface RoleTranslations {
  [key: string]: string;
}

export type PortalConfigResponse = PortalConfig;
