/**
 * Value in minutes before the AuthContext requests a new authentication token
 * The new session request is only requested if the timer is below x minutes
 */
import { SimStatus } from '../models/UserDevicesModels';

export const SessionRenewThreshold = 25;
/**
 * Timeout in milliseconds before the session expired page requests a logout request
 */
export const SessionExpiredLogoutTimeout = 5000;
/**
 * Axios timeout in milliseconds config for every request
 */
export const AxiosRequestTimeout = 45000;
/**
 * Max size of imports in MB
 */
export const BulkImportMaxFileSize = 25;
/**
 * Temp solution for new admins with VDF roles - the default company code is the 'VF_ESIM_SYSTEM'
 */
export const DefaultCompanyCode = 'VF_ESIM_SYSTEM';
/**
 * Portal languages
 */
export type PortalLanguages = 'en' | 'de' | 'ie';
/**
 * Preference status sort
 */
export const SimStatusSortPreference = [
  SimStatus.PENDING,
  SimStatus.PROCESSING,
  SimStatus.SUSPENDED,
  SimStatus.ERROR,
  SimStatus.CONNECTED,
  SimStatus.FREE,
  SimStatus.TERMINATED,
];
