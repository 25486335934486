import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  FetchNotificationsRequest,
  FetchNotificationsResponse,
  MarkNotificationAsReadRequest,
  MarkNotificationAsReadResponse,
} from '../../models/NotificationsModels';

const LiveNotificationsService = {
  api: {
    fetchNotifications: (params: FetchNotificationsRequest): AxiosPromise<FetchNotificationsResponse> =>
      Api.post(`/notifications`, params),
    markNotificationAsRead: (params: MarkNotificationAsReadRequest): AxiosPromise<MarkNotificationAsReadResponse> =>
      Api.post(`/notifications/read`, params),
    // TODO: remove this
    addDummyNotification: (params: { adminId: string; actionId: string; status: 'ERROR' | 'SUCCESS' }): AxiosPromise =>
      Api.post(`/wit/authenticate/add-dummy`, params),
  },
};

export default LiveNotificationsService;
