import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import { PortalConfigResponse } from '../../models/PortalConfigsModels';

const PortalConfigsService = {
  api: {
    fetchConfigs: (): AxiosPromise<PortalConfigResponse> => Api.get(`/portal/configs`),
  },
};

export default PortalConfigsService;
