import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import usePortalConfig from '../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { DevicesReportDropdownFilters } from '../../../models/DevicesReportModels';
import DevicesReportService from '../../../services/DevicesReports/DevicesReportService';
import { setCompanyList } from '../../../stores/DevicesReportStore';

function useDevicesReportFiltersDrawer(selectedManufacturer?: string, selectedCountry?: string) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<DevicesReportDropdownFilters | null>(null);
  const [deviceManufacturerOptions, setDeviceManufacturerOptions] = useState<DropdownItem[]>([]);
  const [deviceModelOptions, setDeviceModelOptions] = useState<DropdownItem[]>([]);
  const [companyOptions, setCompanyOptions] = useState<DropdownItem[]>([]);
  const [countryOptions, setCountryOptions] = useState<DropdownItem[]>([]);
  const [uemTypesOptions, setUemTypesOptions] = useState<DropdownItem[]>([]);
  const [deviceSourceOptions, setDeviceSourceOptions] = useState<DropdownItem[]>([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<DropdownItem[]>([]);
  const { featurePermissions } = usePortalConfig();
  const snackBar = useSnackBar();
  const { isUserAllowed } = useRolePermissions();
  const isAllowedToSelectCompany = isUserAllowed(featurePermissions.selectCompanyAuditTrail);
  const isAllowedToSelectCountries = isUserAllowed(featurePermissions.selectCountriesAuditTrail);

  /**
   * Gets the list of all the companies
   * Useful when the country was not selected
   */
  const getAllCompanies = (f: DevicesReportDropdownFilters) => {
    const companies = f.countries.map((c) => c.companies).flat();
    dispatch(setCompanyList(companies));
    return companies;
  };
  /**
   * Returns a list of companies for a given country
   * @param f
   * @param country
   */
  const getCompaniesByCountry = (f: DevicesReportDropdownFilters, country: string) =>
    f.countries
      .filter((c) => c.countryCode === country)
      .map((c) => c.companies)
      .flat();

  /**
   * Check if the company belongs to a given country
   * @param country
   * @param companyCode
   */
  const isCompanyInCountry = (country: string, companyCode: string) => {
    const countryFilter = filters?.countries.find((c) => c.countryCode === country);
    if (!countryFilter) {
      return false;
    }
    return countryFilter.companies.some((company) => company.companyCode === companyCode);
  };

  /**
   * Creates the company selection filter options
   * @param f
   */
  const createCompanyDropdownOptions = (f: DevicesReportDropdownFilters | null) => {
    if (f) {
      if (selectedCountry) {
        const options = getCompaniesByCountry(f, selectedCountry).map((c) => ({
          key: c.companyCode,
          label: c.name,
          value: c.companyCode,
        }));
        setCompanyOptions(options);
      } else {
        const options = getAllCompanies(f).map((c) => ({
          key: c.companyCode,
          label: c.name,
          value: c.companyCode,
        }));
        setCompanyOptions(options);
      }
    }
  };

  /**
   * Creates the filters that are dropdowns
   * @param f
   */
  const createDropdownOptions = (f: DevicesReportDropdownFilters) => {
    setDeviceManufacturerOptions(
      f.manufacturers.map((m) => ({
        key: m.manufacturer,
        label: m.manufacturer,
        value: m.manufacturer,
      })),
    );
    setDeviceTypeOptions(
      f.types.sort().map((d) => ({
        key: d,
        label: t(`common.deviceType.${d}`),
        value: d,
      })),
    );
    setDeviceSourceOptions(
      f.sources.map((s) => ({
        key: s,
        label: s,
        value: s,
      })),
    );
    setCountryOptions(
      f.countries.map((c) => ({
        key: c.countryCode,
        label: c.country,
        value: c.countryCode,
      })),
    );
    setUemTypesOptions(
      f.uemSystems.map((type) => ({
        key: type,
        label: t(`pages.uemOnboarding.uemTypes.${type}`),
        value: type,
      })),
    );
    createCompanyDropdownOptions(f);
  };

  /**
   * Creates the dropdown options for the device model based on the selected manufacturer
   */
  const createDeviceModelOptions = () => {
    const manufacturer = filters?.manufacturers.find((m) => m.manufacturer === selectedManufacturer);
    if (manufacturer) {
      setDeviceModelOptions(
        manufacturer.models.map((m) => ({
          key: m,
          label: m,
          value: m,
        })),
      );
    }
  };

  /**
   * Fetches the list of devices report filters
   */
  const fetchFilters = () => {
    DevicesReportService.api.fetchFilters().then(
      (response) => {
        setFilters(response.data);
        createDropdownOptions(response.data);
      },
      () => {
        snackBar.showSnackBar(t('pages.devicesReport.fetchFiltersError'), 'error');
      },
    );
  };

  useEffect(() => {
    if (selectedManufacturer) {
      createDeviceModelOptions();
    } else {
      setDeviceModelOptions([]);
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    createCompanyDropdownOptions(filters);
  }, [selectedCountry]);

  useEffect(() => {
    fetchFilters();
  }, []);

  return {
    deviceTypeOptions,
    deviceManufacturerOptions,
    deviceModelOptions,
    deviceSourceOptions,
    companyOptions,
    countryOptions,
    uemTypesOptions,
    isAllowedToSelectCompany,
    isAllowedToSelectCountries,
    isCompanyInCountry,
  };
}

export default useDevicesReportFiltersDrawer;
