import { Box, useTheme } from '@mui/material';
import { memo } from 'react';
import { Sim } from '../../models/UserDevicesModels';
import StatusContainer, { Tooltip } from './StatusTagStyles';
import useSimStatus from './useSimStatus';

interface SimStatusTagProps {
  sim: Sim;
  label: string;
}

function SimStatusTag({ sim, label }: SimStatusTagProps) {
  const theme = useTheme();
  const { tooltipMessage } = useSimStatus(sim);
  const colors = {
    CONNECTED: { font: '#A8B400', bg: 'rgba(168, 180, 0, 0.10)' },
    NOT_CONNECTED: { font: theme.palette.text.secondary, bg: 'rgba(102, 102, 102, 0.10)' },
    FREE: { font: theme.palette.text.secondary, bg: 'rgba(102, 102, 102, 0.10)' },
    PROCESSING: { font: '#EB9700', bg: 'rgba(235, 151, 0, 0.1)' },
    PENDING: { font: '#EB9700', bg: 'rgba(235, 151, 0, 0.1)' },
    ERROR: { font: theme.palette.primary.main, bg: 'rgba(230, 0, 0, 0.10)' },
    SUSPENDED: { font: '#007C92', bg: 'rgba(0, 124, 146, 0.1)' },
    TERMINATED: { font: '#007C92', bg: 'rgba(0, 124, 146, 0.1)' },
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip arrow title={`${tooltipMessage}`} placement="top" disableHoverListener={!tooltipMessage}>
        <StatusContainer colors={colors[sim.status]}>
          <span>{label}</span>
        </StatusContainer>
      </Tooltip>
    </Box>
  );
}

export default memo(SimStatusTag);
