import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner';

function LoadingPage() {
  const [t] = useTranslation();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '3rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LoadingSpinner style={{ minHeight: '148px', maxWidth: '148px' }} />
        <Typography sx={{ fontSize: '1.75rem', lineHeight: '2rem', fontFamily: 'Vodafone Lt' }}>
          {t('common.loading')}
        </Typography>
      </Box>
    </Box>
  );
}

export default LoadingPage;
