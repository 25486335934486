import { Box, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MenuItemWrapper from '../../../../components/Dropdown/DropdownStyle';
import VerticalDotsIcon from '../../../../components/Icons/VerticalDotsIcon';
import MenuWrapper from '../../../../components/Menu/MenuStyle';
import UserDetailsContext from '../../../../contexts/UserDetailsContext/UserDetailsContext';
import usePortalConfig from '../../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../../hooks/useRolePermissions/useRolePermissions';
import { Device, GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../models/UserDevicesModels';
import {
  setDevice,
  updateRequest,
  updateSelectedDropdownUser,
  updateTypeOfConnection,
} from '../../../../stores/NewConnectionStore';
import { setDeviceToUpgrade } from '../../../../stores/UpgradeDeviceStore';
import NewConnectionDrawer from '../../components/NewConnectionDrawer/NewConnectionDrawer';
import RestoreConnectionDrawer from './RestoreConnectionDrawer/RestoreConnectionDrawer';
import SuspendSimDrawer from './SuspendSimDrawer/SuspendSimDrawer';
import TerminateConnectionDrawer from './TerminateConnectionDrawer/TerminateConnectionDrawer';
import UpgradeDeviceDrawer from './UpgradeDeviceDrawer/UpgradeDeviceDrawer';

interface UserDetailsDeviceRowOptionsProps {
  sim: Sim;
  resendNotification: (deviceId: string) => void;
  isPerformingAction: boolean;
  getDeviceById: GetDeviceFn | GetDeviceByIdFn;
  onNewConnectionSuccess: () => void;
  onUpgradeSuccess: () => void;
  onSuspendSimSuccess: () => void;
  onRestoreConnectionSuccess: () => void;
  onTerminateConnectionSuccess: () => void;
}
function UserDetailsDeviceRowOptions({
  sim,
  resendNotification,
  isPerformingAction,
  getDeviceById,
  onNewConnectionSuccess,
  onUpgradeSuccess,
  onSuspendSimSuccess,
  onRestoreConnectionSuccess,
  onTerminateConnectionSuccess,
}: UserDetailsDeviceRowOptionsProps) {
  const [t] = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // TODO: refactor this wall of state :-D
  const [showUpgradeDevice, setShowUpgradeDevice] = useState(false);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showSuspendSim, setShowSuspendSim] = useState(false);
  const [showRestoreConnection, setShowRestoreConnection] = useState(false);
  const [showTerminateConnection, setShowTerminateConnection] = useState(false);
  const dispatch = useDispatch();
  const { isUserAllowed } = useRolePermissions();
  const user = useContext(UserDetailsContext);
  const { featurePermissions } = usePortalConfig();

  /**
   * Handle button click
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(setDeviceToUpgrade({ device: getDeviceById(sim.deviceId) as Device, sim }));
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Initiates the new connection journey, beginning on the notifications step
   */
  const initNewConnectionJourney = () => {
    if (user) {
      handleClose();
      dispatch(
        updateSelectedDropdownUser({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
          key: user.id,
          secondaryLabel: user.email,
          data: user,
        }),
      );
      dispatch(updateTypeOfConnection('ESIM'));
      dispatch(updateRequest({ userId: user?.id as string, eid: sim.simId }));
      dispatch(setDevice(getDeviceById(sim.deviceId) as Device));
      setShowNewConnection(true);
    }
  };

  /**
   * Inits the suspend sim journey
   */
  const initSuspendSimJourney = () => {
    handleClose();
    setShowSuspendSim(true);
  };

  /**
   * Inits the terminate connection journey
   */
  const initTerminateConnectionJourney = () => {
    handleClose();
    setShowTerminateConnection(true);
  };

  /**
   * Inits the restore connection journey
   */
  const initRestoreJourney = () => {
    handleClose();
    setShowRestoreConnection(true);
  };

  /**
   * Render upgrade option for current device - SIM swap
   */
  const renderUpgradeOption = () => {
    if (
      (isUserAllowed(featurePermissions.userDevicesActions) && sim.status === 'CONNECTED') ||
      sim.status === 'SUSPENDED'
    ) {
      return (
        <MenuItemWrapper
          onClick={() => {
            handleClose();
            setShowUpgradeDevice(true);
          }}
        >
          {t('pages.userDetails.tabs.devicesTable.options.upgradeDevice')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render new connection option
   */
  const renderNewConnectionOption = () => {
    if (user && isUserAllowed(featurePermissions.userDevicesActions) && sim.status === 'FREE' && sim.type !== 'PSIM') {
      return (
        <MenuItemWrapper onClick={initNewConnectionJourney}>
          {t('pages.userDetails.tabs.devicesTable.options.newConnection')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render resend option for current device
   */
  const renderResendOption = () => {
    if (isUserAllowed(featurePermissions.userDevicesActions) && sim.status === 'ERROR') {
      return (
        <MenuItemWrapper
          disabled={isPerformingAction}
          onClick={() => {
            handleClose();
            resendNotification(sim.id);
          }}
        >
          {t('pages.userDetails.tabs.devicesTable.options.resend')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render suspend SIM option
   */
  const renderSuspendSimOption = () => {
    if (isUserAllowed(featurePermissions.userDevicesActions) && sim.status === 'CONNECTED' && sim.type !== 'PSIM') {
      return (
        <MenuItemWrapper onClick={initSuspendSimJourney} sx={{ color: theme.palette.primary.main }}>
          {t('pages.userDetails.tabs.devicesTable.options.suspendSim')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render terminate connection option
   */
  const renderTerminateConnection = () => {
    if (
      isUserAllowed(featurePermissions.userDevicesActions) &&
      (sim.status === 'CONNECTED' || sim.status === 'SUSPENDED') &&
      sim.type !== 'PSIM'
    ) {
      return (
        <MenuItemWrapper onClick={initTerminateConnectionJourney} sx={{ color: theme.palette.primary.main }}>
          {t('pages.userDetails.tabs.devicesTable.options.terminateConnection')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render restore option
   */
  const renderRestoreOption = () => {
    if (isUserAllowed(featurePermissions.userDevicesActions) && sim.status === 'SUSPENDED') {
      return (
        <MenuItemWrapper onClick={initRestoreJourney}>
          {t('pages.userDetails.tabs.devicesTable.options.restore')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  return (
    <>
      {isUserAllowed(featurePermissions.userDevicesActions) ? (
        <Box
          id={`box-${sim.id}`}
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.375rem',
            width: '2.25rem',
            height: '2.25rem',
            flexShrink: 0,
            ':hover': { backgroundColor: theme.palette.monochrome.monoChrome11, cursor: 'pointer' },
          }}
          onClick={handleClick}
        >
          <VerticalDotsIcon />
        </Box>
      ) : null}
      {sim.status !== 'PENDING' ? (
        <Box>
          <MenuWrapper
            id={`menu-${sim.id}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {renderNewConnectionOption()}
            {renderResendOption()}
            {renderUpgradeOption()}
            {renderSuspendSimOption()}
            {renderTerminateConnection()}
            {renderRestoreOption()}
          </MenuWrapper>
        </Box>
      ) : null}
      {showSuspendSim ? (
        <SuspendSimDrawer
          sim={sim}
          open={showSuspendSim}
          onClose={() => setShowSuspendSim(false)}
          onSuspendSimSuccess={onSuspendSimSuccess}
          getDeviceById={getDeviceById}
        />
      ) : null}
      {showUpgradeDevice ? (
        <UpgradeDeviceDrawer
          open={showUpgradeDevice}
          onClose={() => setShowUpgradeDevice(false)}
          onUpgradeSuccess={onUpgradeSuccess}
        />
      ) : null}
      {showNewConnection ? (
        <NewConnectionDrawer
          onClose={() => {
            setShowNewConnection(false);
          }}
          open={showNewConnection}
          onNewConnectionSuccess={onNewConnectionSuccess}
          initialStep={7}
        />
      ) : null}
      {showRestoreConnection ? (
        <RestoreConnectionDrawer
          onClose={() => {
            setShowRestoreConnection(false);
          }}
          open={showRestoreConnection}
          onRestoreConnectionSuccess={onRestoreConnectionSuccess}
          getDeviceById={getDeviceById}
          sim={sim}
        />
      ) : null}
      {showTerminateConnection ? (
        <TerminateConnectionDrawer
          onClose={() => {
            setShowTerminateConnection(false);
          }}
          open={showTerminateConnection}
          sim={sim}
          onTerminateConnectionSuccess={onTerminateConnectionSuccess}
        />
      ) : null}
    </>
  );
}

export default UserDetailsDeviceRowOptions;
