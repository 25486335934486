import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserDetailsContext from '../../../../../contexts/UserDetailsContext/UserDetailsContext';
import useModal from '../../../../../hooks/useModal/useModal';
import useSnackBar from '../../../../../hooks/useSnackBar/useSnackBar';
import { NotificationSetting } from '../../../../../models/CompanyOnboardingModels';
import { AvailableNotificationsResponse } from '../../../../../models/MarketsModels';
import { Device } from '../../../../../models/UserDevicesModels';
import CompanyOnboardingService from '../../../../../services/CompanyOnboarding/CompanyOnboardingService';
import MarketsService from '../../../../../services/Markets/MarketsService';
import UsersService from '../../../../../services/Users/UsersService';
import { RootState } from '../../../../../stores/Store';
import { setNotificationSettings, updateRequest } from '../../../../../stores/UpgradeDeviceStore';

function UseUpgradeDevice(onClose: () => void, onUpgradeSuccess: () => void) {
  const [availableNotifications, setAvailableNotifications] = useState<AvailableNotificationsResponse>();
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const user = useContext(UserDetailsContext);
  const { upgradeDeviceRequest, newDevice, qrCodeNotificationSettings } = useSelector(
    (state: RootState) => state.upgradeDeviceReducer,
  );
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  /**
   * Perform the request to upgrade the device
   */
  const upgradeDevice = () => {
    setIsLoading(true);
    UsersService.api
      .upgradeUserDevice(upgradeDeviceRequest)
      .then(
        () => {
          modal.showModal({
            modalTitle: t('pages.userDetails.upgradeDeviceDrawer.upgradeSuccessTitle'),
            modalDescription: upgradeDeviceRequest.notifyAdmin
              ? t('pages.userDetails.upgradeDeviceDrawer.upgradeSuccessMessage')
              : t('pages.userDetails.upgradeDeviceDrawer.upgradeSuccessMessageNoNotif'),
            onAccept: (setOpen) => {
              setOpen(false);
              onUpgradeSuccess();
            },
          });
          onClose();
        },
        (err) => {
          if (err.code === 'ECONNABORTED') {
            snackBar.showSnackBar(t('pages.userDetails.upgradeDeviceDrawer.upgradeTimeout'), 'warning');
            onClose();
          } else {
            snackBar.showSnackBar(t('pages.userDetails.upgradeDeviceDrawer.upgradeError'), 'error');
          }
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Requests the available notifications types for the market of the ban
   * @param ban
   */
  const getAvailableNotifications = (ban: string) => {
    MarketsService.api.fetchMarketsNotifications(ban, 'UPGRADE').then(
      (res) => {
        setAvailableNotifications(res.data);
        if ((res.data.enablePush && !res.data.enablePull) || (!res.data.enablePush && res.data.enablePull)) {
          dispatch(updateRequest({ notificationType: res.data.enablePull ? 'PULL' : 'PUSH' }));
        }
      },
      () => {
        snackBar.showSnackBar(t('pages.userDetails.upgradeDeviceDrawer.notificationsAvailableError'), 'error');
      },
    );
  };

  /**
   * Requests the available devices to be upgraded to
   * @param
   */
  const getAvailableDevices = () => {
    setIsLoading(true);
    if (id) {
      UsersService.api
        .fetchFreeUserDevices(id)
        .then(
          (res) => {
            setAvailableDevices(res.data.devices);
          },
          () => {
            snackBar.showSnackBar(t('pages.userDetails.upgradeDeviceDrawer.devicesAvailableError'), 'error');
          },
        )
        .finally(() => setIsLoading(false));
    }
  };

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    CompanyOnboardingService.api.fetchCompanyNotificationSettings(companyId).then(
      (res) => {
        dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
      },
      () => {
        snackBar.showSnackBar(
          t('pages.userDetails.upgradeDeviceDrawer.fetchCompanyNotificationsDefaultError'),
          'error',
        );
      },
    );
  };

  useEffect(() => {
    if (user?.billingAccountBan) {
      getAvailableNotifications(user.billingAccountBan);
      getAvailableDevices();
    }
  }, [user?.billingAccountBan]);

  useEffect(() => {
    if (newDevice?.companyId && !qrCodeNotificationSettings.length) {
      getNotificationSettings(newDevice.companyId);
    }
  }, [newDevice]);

  return { upgradeDevice, availableNotifications, availableDevices, isLoading };
}

export default UseUpgradeDevice;
