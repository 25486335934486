import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import usePortalConfig from '../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { AuditCompany } from '../../../models/CompanyOnboardingModels';
import { DeviceType } from '../../../models/UserDevicesModels';
import CompanyOnboardingService from '../../../services/CompanyOnboarding/CompanyOnboardingService';
import MarketsService from '../../../services/Markets/MarketsService';
import UemOnboardingService from '../../../services/UemOnboarding/UemOnboardingService';
import { setCompanyList } from '../../../stores/AuditTrailStore';

function useAuditTrailFiltersDrawer(selectedCompany?: string) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState<AuditCompany[]>([]);
  const [companyOptions, setCompanyOptions] = useState<DropdownItem[]>([]);
  const [countryOptions, setCountryOptions] = useState<DropdownItem[]>([]);
  const [uemTypesOptions, setUemTypesOptions] = useState<DropdownItem[]>([]);
  const [banOptions, setBanOptions] = useState<DropdownItem[]>([]);
  const { featurePermissions } = usePortalConfig();
  const deviceTypeOptions = Object.keys(DeviceType).map((dev) => ({
    key: dev,
    value: dev,
    label: t(`common.deviceType.${dev}`),
  }));
  const eidSourceOptions = [
    { key: 'UEM', value: 'UEM', label: 'UEM' },
    { key: 'CSV', value: 'CSV', label: 'CSV' },
  ];
  const snackBar = useSnackBar();
  const { isUserAllowed } = useRolePermissions();
  const isAllowedToSelectCompany = isUserAllowed(featurePermissions.selectCompanyAuditTrail);
  const isAllowedToSelectCountries = isUserAllowed(featurePermissions.selectCountriesAuditTrail);

  /**
   * Returns the list of bans for a selected company
   * @param companyCode
   */
  const getBanOptions = (companyCode: string | undefined) => {
    if (!companyCode) setBanOptions([]);
    if (companies) {
      const comp = companies.find((c) => c.code === companyCode);
      if (comp?.bans?.length) {
        setBanOptions(
          comp.bans.map((ban) => ({
            key: ban.ban,
            value: ban.ban,
            label: ban.name,
          })),
        );
      }
    }
  };

  /**
   * Fetches the list of companies
   */
  const fetchCompanies = () => {
    CompanyOnboardingService.api.fetchAuditCompanies().then(
      (response) => {
        setCompanies(response.data.companies);
        const companiesDropdownOptions = response.data.companies.map((obj) => ({
          key: obj.code,
          value: obj.code,
          label: `${obj.name}`,
        }));
        setCompanyOptions(companiesDropdownOptions);
        dispatch(setCompanyList(response.data.companies));
      },
      () => {
        snackBar.showSnackBar(t('pages.adminOnboarding.fetchCompaniesError'), 'error');
      },
    );
  };

  /**
   * Fetch the list of countries
   */
  const fetchCountries = () => {
    MarketsService.api.fetchMarkets().then((response) => {
      const countryCodesDropdownOptions = response.data.markets
        .filter(
          (market, index, marketsList) =>
            marketsList.findIndex(
              (market2) => market2.countryCode === market.countryCode && market2.marketType === 'COUNTRY',
            ) === index,
        )
        .map((obj) => ({
          key: obj.countryCode,
          value: obj.countryCode,
          label: `${obj.name}`,
        }));

      setCountryOptions(countryCodesDropdownOptions);
    });
  };

  /**
   * Fetches the list of UEM types
   */
  const fetchUemTypes = () => {
    UemOnboardingService.api.fetchUemTypes().then(
      (response) => {
        const uemTypesDropdownOptions = [
          ...response.data.uemTypeList.map((obj) => ({
            key: obj.type,
            value: obj.type,
            label: t(`pages.uemOnboarding.uemTypes.${obj.type}`),
          })),
        ];
        setUemTypesOptions([...uemTypesDropdownOptions]);
      },
      () => {
        snackBar.showSnackBar(t('pages.uemOnboarding.getUemTypesError'), 'error');
      },
    );
  };

  useEffect(() => {
    getBanOptions(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    fetchCompanies();
    fetchCountries();
    fetchUemTypes();
  }, []);

  return {
    companyOptions,
    countryOptions,
    banOptions,
    eidSourceOptions,
    deviceTypeOptions,
    uemTypesOptions,
    isAllowedToSelectCompany,
    isAllowedToSelectCountries,
  };
}

export default useAuditTrailFiltersDrawer;
