import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const SideToastContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  transition: 'opacity 0.5s ease-in-out',
  flexGrow: 1,
}));

interface IconContainerProps extends BoxProps {
  backgroundColor: string;
}

export const SeverityIconContainer = styled(Box)<IconContainerProps>(({ backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0.5rem 0 0.5rem',
  backgroundColor,
  minHeight: '100%',
  flexShrink: 0,
  justifyContent: 'center',
}));

export const TemplateContainer = styled(Box)<BoxProps>(() => ({}));

export const CloseIconContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '1.25rem 1.25rem 1.25rem 0',
  flexShrink: 0,
}));

export const MessageContainer = styled(Box)<BoxProps>(() => ({
  padding: '1.25rem 0 1.25rem 1.25rem',
  marginRight: '0.5rem',
  flexGrow: 1,
  overflowY: 'hidden',
}));

export const MessageTitle = styled(Typography)<TypographyProps>(() => ({
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '1.5rem',
  paddingBottom: '0.5rem',
  // TODO: add elipsis
}));

export default SideToastContainer;
