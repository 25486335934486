import i18next from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalLanguages } from '../../configs/PortalConfig';
import { PortalConfigContext } from '../../contexts/PortalConfigContext/PortalConfigContext';
import { FeaturePermissionType, RoutePermissionType } from '../../models/PortalConfigsModels';

function usePortalConfig() {
  const context = useContext(PortalConfigContext);
  const { language } = i18next;
  const [t] = useTranslation();

  /**
   * Retrieves the translation of a given role
   * @param role
   */
  const getRoleTranslation = (role: string) => {
    if (context.portalConfig) {
      const roleObj = context.portalConfig.roles.find((r) => r.role === role);
      return roleObj?.translations[language as PortalLanguages] ?? t('common.na');
    }
    return t('common.na');
  };

  if (!context) {
    throw new Error('usePortalConfig must be used within an PortalProvider');
  }
  if (context.portalConfig) {
    return {
      featurePermissions: context.portalConfig.featurePermissions,
      routePermissions: context.portalConfig.routePermissions,
      roles: context.portalConfig.roles,
      getRoleTranslation,
    };
  }

  const featurePermissions = Object.values(FeaturePermissionType).reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {} as { [key in FeaturePermissionType]: string[] });

  const routePermissions = Object.values(RoutePermissionType).reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {} as { [key in RoutePermissionType]: string[] });

  return { featurePermissions, routePermissions, roles: [], getRoleTranslation };
}
export default usePortalConfig;
