import { Box, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import TextInput from '../../../../components/TextInput/TextInput';
import {
  EidLength,
  ImeiLength,
  NumberLettersRegex,
  NumbersLettersSpaceDotRegex,
} from '../../../../configs/RegexConfig';
import usePortalConfig from '../../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../../hooks/useRolePermissions/useRolePermissions';
import { Device, DeviceType, DeviceUpdateRequest } from '../../../../models/UserDevicesModels';

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid${theme.palette.disabled.main}`,
  padding: '0.5rem',
  minHeight: '4rem',
  alignItems: 'center',
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 'normal',
  '& .label': {
    color: theme.palette.text.disabled,
  },
}));

interface DeviceDetailsInfoRowsProps {
  device: Device | undefined | null;
  isLoading: boolean;
  updateDeviceDetails: (d: DeviceUpdateRequest, setFormOpen: (val: boolean) => void) => void;
}
function DeviceDetailsInfoRows({ device, isLoading, updateDeviceDetails }: DeviceDetailsInfoRowsProps) {
  const [t] = useTranslation();
  const { isUserAllowed } = useRolePermissions();
  const { routePermissions } = usePortalConfig();
  const [formOpen, setFormOpen] = useState(false);
  const deviceTypeOptions = Object.keys(DeviceType).map((dev) => ({
    key: dev,
    value: dev,
    label: t(`common.deviceType.${dev}`),
  }));

  const formik = useFormik({
    initialValues: {
      manufacturer: device?.manufacturer || '',
      model: device?.model || '',
      os: device?.os || '',
      serialNumber: device?.serialNumber || '',
      deviceType: device?.deviceType || '',
      eid: device?.eid || '',
      imeiOne: device?.imeiOne || '',
      imeiTwo: device?.imeiTwo || '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      manufacturer: yup.string().required(t('common.inputValidations.requiredField')),
      model: yup.string().required(t('common.inputValidations.requiredField')),
      os: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumbersLettersSpaceDotRegex, t('common.inputValidations.onlyNumbersAndLettersAndDot')),
      serialNumber: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersRegex, t('common.inputValidations.onlyNumbersAndLetters')),
      deviceType: yup.string().required(t('common.inputValidations.requiredField')),
      eid: yup.string().when([], {
        is: () => device?.simType !== 'PSIM',
        then: () =>
          yup
            .string()
            .required(t('common.inputValidations.requiredField'))
            .max(EidLength, t('common.inputValidations.maxLength', { len: EidLength }))
            .matches(/^[0-9]+$/, t('common.inputValidations.onlyNumbers')),
        otherwise: () =>
          yup
            .string()
            .notRequired()
            .max(EidLength, t('common.inputValidations.maxLength', { len: EidLength }))
            .matches(/^[0-9]+$/, t('common.inputValidations.onlyNumbers')),
      }),
      imeiOne: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .max(ImeiLength, t('common.inputValidations.maxLength', { len: ImeiLength }))
        .matches(/^[0-9]+$/, t('common.inputValidations.onlyNumbers')),
      imeiTwo: yup
        .string()
        .max(ImeiLength, t('common.inputValidations.maxLength', { len: ImeiLength }))
        .matches(/^[0-9]+$/, t('common.inputValidations.onlyNumbers')),
    }),
    onSubmit: (values) => {
      if (device) {
        updateDeviceDetails({ id: device.id, ...values, deviceType: values.deviceType as DeviceType }, setFormOpen);
      }
    },
  });

  return (
    <Grid container sx={{ paddingTop: '1.5rem', paddingBottom: '2.5rem' }}>
      {!formOpen ? (
        <Grid item xl={6} md={12} xs={12}>
          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.manufacturer')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device ? device.manufacturer : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.model')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device ? device.model : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.os')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device && device.os ? device.os : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.serialNumber')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device && device.serialNumber ? device.serialNumber : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.deviceType')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device ? t(`translation:common.deviceType.${device.deviceType}`) : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.eid')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device ? device.eid : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          <Row>
            <Grid container rowSpacing="0.5rem">
              <Grid item xl={3} md={4} xs={12} className="label">
                {t('translation:pages.deviceDetails.imeiOne')}
              </Grid>
              <Grid item xl={9} md={8} xs={12}>
                {device ? device.imeiOne : <Skeleton width="50%" />}
              </Grid>
            </Grid>
          </Row>

          {device?.imeiTwo ? (
            <Row>
              <Grid container rowSpacing="0.5rem">
                <Grid item xl={3} md={4} xs={12} className="label">
                  {t('translation:pages.deviceDetails.imeiTwo')}
                </Grid>
                <Grid item xl={9} md={8} xs={12}>
                  {device ? device.imeiTwo : <Skeleton width="50%" />}
                </Grid>
              </Grid>
            </Row>
          ) : null}
          {isUserAllowed(routePermissions.editDevices) ? (
            <Button
              sx={{ marginTop: '2.5rem' }}
              label={t('pages.deviceDetails.editInformation')}
              color="primaryButton"
              type="button"
              onClick={() => setFormOpen(!formOpen)}
            />
          ) : null}
        </Grid>
      ) : (
        <Grid item xl={4} md={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              autoComplete="off"
              key="manufacturer"
              id="manufacturer"
              name="manufacturer"
              type="text"
              label={t(`pages.deviceDetails.manufacturer`)}
              value={formik.values.manufacturer}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
              feedback={
                formik.touched.manufacturer && Boolean(formik.errors.manufacturer) ? formik.errors.manufacturer : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.manufacturer`).toLowerCase()
              }
            />
            <TextInput
              autoComplete="off"
              key="model"
              id="model"
              name="model"
              type="text"
              label={t(`pages.deviceDetails.model`)}
              value={formik.values.model}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.model && Boolean(formik.errors.model)}
              feedback={formik.touched.model && Boolean(formik.errors.model) ? formik.errors.model : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.model`).toLowerCase()}
            />

            <TextInput
              autoComplete="off"
              key="os"
              id="os"
              name="os"
              type="text"
              label={t(`pages.deviceDetails.os`)}
              value={formik.values.os}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.os && Boolean(formik.errors.os)}
              feedback={formik.touched.os && Boolean(formik.errors.os) ? formik.errors.os : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.os`).toLowerCase()}
            />

            <TextInput
              autoComplete="off"
              key="serialNumber"
              id="serialNumber"
              name="serialNumber"
              type="text"
              label={t(`pages.deviceDetails.serialNumber`)}
              value={formik.values.serialNumber}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
              feedback={
                formik.touched.serialNumber && Boolean(formik.errors.serialNumber) ? formik.errors.serialNumber : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.serialNumber`).toLowerCase()
              }
            />

            <Dropdown
              isPlaceholderDisabled={false}
              id="deviceType"
              name="deviceType"
              label={t('pages.deviceDetails.deviceType')}
              value={formik.values.deviceType}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.deviceType && Boolean(formik.errors.deviceType)}
              feedback={formik.touched.deviceType && Boolean(formik.errors.deviceType) ? formik.errors.deviceType : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={deviceTypeOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') + t('pages.deviceDetails.deviceType').toLowerCase()
              }
            />

            <TextInput
              autoComplete="off"
              key="eid"
              id="eid"
              name="eid"
              type="text"
              label={t(`pages.deviceDetails.eid`)}
              value={formik.values.eid}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.eid && Boolean(formik.errors.eid)}
              feedback={formik.touched.eid && Boolean(formik.errors.eid) ? formik.errors.eid : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.eid`)}
            />

            <TextInput
              autoComplete="off"
              key="imeiOne"
              id="imeiOne"
              name="imeiOne"
              type="text"
              label={t(`pages.deviceDetails.imeiOne`)}
              value={formik.values.imeiOne}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.imeiOne && Boolean(formik.errors.imeiOne)}
              feedback={formik.touched.imeiOne && Boolean(formik.errors.imeiOne) ? formik.errors.imeiOne : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.imeiOne`)}
            />

            <TextInput
              autoComplete="off"
              key="imeiTwo"
              id="imeiTwo"
              name="imeiTwo"
              type="text"
              label={t(`pages.deviceDetails.imeiTwo`)}
              value={formik.values.imeiTwo}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.imeiTwo && Boolean(formik.errors.imeiTwo)}
              feedback={formik.touched.imeiTwo && Boolean(formik.errors.imeiTwo) ? formik.errors.imeiTwo : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.deviceDetails.imeiTwo`)}
            />

            <ButtonGroup sx={{ paddingTop: '0.5rem' }}>
              <Button
                label={t('common.cancel')}
                color="alternative2Button"
                type="submit"
                onClick={() => {
                  setFormOpen(!formOpen);
                  formik.resetForm();
                }}
              />
              <Button label={t('common.saveChanges')} color="primaryButton" type="submit" disabled={isLoading} />
            </ButtonGroup>
          </form>
        </Grid>
      )}
    </Grid>
  );
}

export default memo(DeviceDetailsInfoRows);
