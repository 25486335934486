import { NotificationType } from './CompanyOnboardingModels';
import { ApiResponse } from './ServiceModels';

export interface Manufacturer {
  manufacturer: string;
  models: string[];
}

export type DeviceSource = 'UEM' | 'CSV';

export enum DeviceType {
  'MOBILE' = 'MOBILE',
  'WATCH' = 'WATCH',
  'TABLET' = 'TABLET',
  'LAPTOP' = 'LAPTOP',
}

export enum DeviceStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export enum SimStatus {
  FREE = 'FREE',
  CONNECTED = 'CONNECTED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
}

export enum SimJourney {
  SIM_SWAP_SOURCE = 'SIM_SWAP_SOURCE',
  SIM_SWAP_DESTINATION = 'SIM_SWAP_DESTINATION',
  TERMINATION = 'TERMINATION',
  NEW_CONNECTION = 'NEW_CONNECTION',
  LOST_STOLEN = 'LOST_STOLEN',
}

/**
 * Device model
 */
export interface Device {
  id: string;
  companyId: string;
  manufacturer: string;
  model: string;
  eid: string;
  simId: string;
  phoneNumber: string;
  simType: 'ESIM' | 'PSIM';
  imei: string;
  deviceType: DeviceType;
  deviceStatus: DeviceStatus;
  serialNumber: string;
  os: string;
  imeiOne: string;
  imeiTwo: string;
}

export type DeviceUpdateRequest = Omit<
  Device,
  'companyId' | 'simId' | 'phoneNumber' | 'simType' | 'deviceStatus' | 'imei'
>;

/**
 * SIM model
 */
export interface Sim {
  id: string;
  deviceId: string;
  iccid: string;
  phoneNumber: string;
  eid: string;
  simId: string;
  imei: string;
  type: 'ESIM' | 'PSIM';
  status: SimStatus;
  journey: SimJourney;
}

/**
 * The list of devices and SIMs
 */
export interface UserDevices {
  devices: Device[];
  sims: Sim[];
}

/**
 * User devices API response
 * Result object of the /devices/user/{userId} request
 */
export interface UserDevicesResponse extends UserDevices, ApiResponse {}

/**
 * Upgrade device request data - for the upgrade device journey
 * POST /journey/update
 */
export interface UpgradeDeviceRequest {
  notifyAdmin: boolean;
  qrCodeNotification: NotificationType;
  notifyDistributionList: boolean;
  journey: '' | 'UPGRADE';
  eid: string;
  userId: string;
  notificationType: '' | 'PULL' | 'PUSH';
  phoneNumber: string;
}

/**
 * New connection request data - create a new connection journey
 * POST /journey/new
 */
export interface NewConnectionRequest {
  notificationType: '' | 'PULL' | 'PUSH';
  userId: string;
  eid: string;
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  qrCodeNotification: NotificationType;
  tariffCode: string;
}

/**
 * Suspend sim request data - for the suspend sim journey
 * POST /journey/suspend
 */
export interface SuspendSimRequest {
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  eid: string;
  userId: string;
  phoneNumber: string;
}

/**
 * Terminate connection request data - for the terminate connection journey
 * POST /journey/termination
 */
export interface TerminateConnectionRequest {
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  eid: string;
  userId: string;
  phoneNumber: string;
  suspendImmediately: boolean;
  notificationRecipient: NotificationType;
  terminationDate: string;
}

export type RestoreConnectionRequest = SuspendSimRequest;

/**
 * Resend notification journey request
 * POST /journey resend
 */
export interface ResendNotificationRequest {
  simId: string;
}

export type ResendNotificationResponse = ApiResponse;

export type NewConnectionResponse = ApiResponse;

export type UpgradeDeviceResponse = ApiResponse;

export type RestoreConnectionResponse = ApiResponse;

export type TerminateConnectionResponse = ApiResponse;

/**
 * Response of the device details request
 * GET /device/:id
 */
export interface DeviceDetailsResponse extends ApiResponse {
  device: Device;
  sims?: Sim[];
}

/**
 * Get devices by id function definition
 */
export interface GetDeviceByIdFn {
  (deviceId: string): Device | undefined;
}

/**
 * Get devices function definition
 */
export interface GetDeviceFn {
  (): Device | null;
}
