import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PlusIcon from '../../components/Icons/PlusIcon';
import PageTitle from '../../components/Text/PageTitle';
import usePortalConfig from '../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import AdminsTable from './AdminsTable';

function Admins() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { isUserAllowed } = useRolePermissions();
  const { routePermissions } = usePortalConfig();

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageTitle>{t('pages.adminOnboarding.admins')}</PageTitle>
        {isUserAllowed(routePermissions.adminsCreate) ? (
          <Button
            sx={{ marginLeft: 'auto' }}
            onClick={() => navigate('/admins/create')}
            label={
              <>
                <Box sx={{ marginRight: '0.5rem' }}>
                  <PlusIcon />
                </Box>
                {t('pages.adminOnboarding.newAdmin')}
              </>
            }
          />
        ) : null}
      </Box>
      <AdminsTable />
    </>
  );
}
export default Admins;
