import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SecurityIcon from '../../../components/Icons/SecurityIcon';
import Table from '../../../components/Table/Table';
import ClickableLinkWithIcon from '../../../components/Text/ClickableLinkWithIcon';
import PageTitle from '../../../components/Text/PageTitle';
import usePortalConfig from '../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../hooks/useRolePermissions/useRolePermissions';
import useLatestTransactions from './useLatestTransactions';

function LatestTransactions() {
  const [t] = useTranslation();
  const { isUserAllowed } = useRolePermissions();
  const { routePermissions } = usePortalConfig();
  const { rows, isLoading, headers } = useLatestTransactions();

  return (
    <Box sx={{ paddingBottom: '2rem' }}>
      {isUserAllowed(routePermissions.auditTrail) ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '1.5rem' }}>
            <PageTitle>{t('pages.dashboard.latestTransactions.title')}</PageTitle>
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <ClickableLinkWithIcon
                onClick={() => {}}
                to="/dashboard/auditTrail"
                sx={{ fontSize: '1.25rem', lineHeight: '1.75rem' }}
                rightIcon={<SecurityIcon />}
              >
                {t('pages.dashboard.auditTrail')}
              </ClickableLinkWithIcon>
            </Box>
          </Box>
          <Table
            headers={headers}
            rows={rows}
            isLoading={isLoading}
            emptyStateMessage={t('pages.dashboard.latestTransactions.noTransactions')}
          />
        </>
      ) : null}
    </Box>
  );
}

export default LatestTransactions;
