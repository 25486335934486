import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DevicesIcon from '../../../components/Icons/DevicesIcon';
import ESimIcon from '../../../components/Icons/ESimIcon';
import UsersIcon from '../../../components/Icons/UsersIcon';
import DashboardCard from './DashboardCard';
import useDashboardCards from './useDashboardCards';

function DashboardCards() {
  const [t] = useTranslation();
  const theme = useTheme();
  const { devicesCounter, usersCounter, eSIMCounter } = useDashboardCards();

  return (
    <Grid container columnSpacing="2rem" sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      {/* Users */}
      <DashboardCard
        key="users"
        title={t('translation:pages.dashboard.cards.users')}
        description={t('translation:pages.dashboard.cards.numberOfCurrentEmployees')}
        value={usersCounter}
        color={theme.palette.colors.green}
        onClick={() => {}}
        icon={<UsersIcon />}
      />

      {/* Devices */}
      <DashboardCard
        key="devices"
        title={t('translation:pages.dashboard.cards.devices')}
        description={t('translation:pages.dashboard.cards.numberOfActiveDevices')}
        value={devicesCounter}
        color={theme.palette.colors.turquoise}
        to="/dashboard/devicesReport"
        onClick={() => {}}
        icon={<DevicesIcon />}
      />

      {/* eSIMs */}
      <DashboardCard
        key="esims"
        title={t('translation:pages.dashboard.cards.eSIM')}
        description={t('translation:pages.dashboard.cards.numberOfCurrentConnections')}
        value={eSIMCounter}
        color={theme.palette.colors.violet}
        onClick={() => {}}
        icon={<ESimIcon />}
      />
    </Grid>
  );
}

export default DashboardCards;
