import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/Checkbox/Checkbox';
import ClickableIcon from '../../../components/Icons/ClickableIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import useModal from '../../../hooks/useModal/useModal';

interface RemoveCompanyIconProps {
  id: string;
  companyName: string;
  deleteCompany: (
    id: string,
    setOpen: (value: boolean | ((prevState: boolean) => boolean)) => void,
    setIsLoading: (isLoading: boolean) => void,
  ) => void;
}
function RemoveCompanyIcon({ id, companyName, deleteCompany }: RemoveCompanyIconProps) {
  const modal = useModal();
  const [t] = useTranslation();

  /**
   * Displays the confirmation modal
   */
  const showConfirmationModal = () => {
    modal.showModal({
      modalTitle: `${t('pages.companyOnboarding.deleteCompany')} ${companyName}?`,
      modalDescription: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ textAlign: 'left' }}>
            <b>{t('pages.companyOnboarding.deleteCompanyMessage1')} - </b>
            {t('pages.companyOnboarding.deleteCompanyMessage2')}
          </span>
          <ul
            style={{
              paddingInlineStart: '1.75rem',
              maxHeight: '8rem',
              overflow: 'auto',
              listStyle: 'inside',
              textAlign: 'left',
              marginTop: '1rem',
            }}
          >
            <li>
              <span>{t('pages.companyOnboarding.deleteCompanyBullet1')}</span>
            </li>
            <li>
              <span>{t('pages.companyOnboarding.deleteCompanyBullet2')}</span>
            </li>
            <li>
              <span>{t('pages.companyOnboarding.deleteCompanyBullet3')}</span>
            </li>
            <li>
              <span>{t('pages.companyOnboarding.deleteCompanyBullet4')}</span>
            </li>
          </ul>
          <Box sx={{ marginTop: '2rem' }}>
            <Checkbox
              sx={{ textAlign: 'left', alignItems: 'start' }}
              onChange={(e) => {
                modal.setConfirmButtonEnabled(e.currentTarget.checked);
              }}
              label={t('pages.companyOnboarding.deleteCompanyCheckbox', { companyName })}
            />
          </Box>
        </Box>
      ),
      confirmationModal: true,
      confirmationButtonLabel: t('common.delete'),
      onAccept: (setOpen) => deleteCompany(id, setOpen, modal.setIsLoading),
      onDiscard: () => modal.setConfirmButtonEnabled(false),
    });
    modal.setConfirmButtonEnabled(false);
  };

  return (
    <ClickableIcon onClick={showConfirmationModal}>
      <DeleteIcon />
    </ClickableIcon>
  );
}

export default RemoveCompanyIcon;
