import { Box } from '@mui/material';
import React, { createContext, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { ModalDescription, ModalTitle } from '../../components/Modal/ModalStyle';

type ModalOptions = {
  modalTitle: string;
  modalDescription: string | React.JSX.Element;
  onAccept: (setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void) => void;
  confirmationModal?: boolean;
  onDiscard?: () => void;
  modalIcon?: null | undefined | React.JSX.Element;
  confirmationButtonLabel?: string;
  cancelButtonLabel?: string;
};
type ModalContextActions = {
  showModal: (opts: ModalOptions) => void;
  setIsLoading: (isLoading: boolean) => void;
  setConfirmButtonEnabled: (confirmButtonEnabled: boolean) => void;
};

export const ModalContext = createContext({} as ModalContextActions);

function ModalProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [confirmButtonEnabled, setConfirmButtonEnabled] = React.useState<boolean>(true);
  const [t] = useTranslation();
  const [options, setOptions] = useState<ModalOptions | null>(null);

  /**
   * Show modal handler
   */
  const showModal = (opts: ModalOptions) => {
    setIsLoading(false);
    setConfirmButtonEnabled(true);
    setOpen(true);
    setOptions(opts);
  };

  /**
   * Handle discard
   */
  const discard = () => {
    if (options?.onDiscard) {
      options.onDiscard();
    }
    setOpen(false);
  };

  /**
   * Handle accept
   */
  const accept = () => {
    options?.onAccept(setOpen);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ showModal, setIsLoading, setConfirmButtonEnabled }}>
      <Modal isOpen={open} onClose={discard}>
        {options?.modalIcon ? <div>{options.modalIcon}</div> : null}
        <ModalTitle>{options?.modalTitle}</ModalTitle>
        <ModalDescription>{options?.modalDescription}</ModalDescription>
        {options?.confirmationModal ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1.5rem' }}>
            <Button
              disabled={isLoading}
              color="secondaryButton"
              label={options?.cancelButtonLabel ? options?.cancelButtonLabel : t('common.cancel')}
              onClick={discard}
            />
            <Button
              disabled={isLoading || !confirmButtonEnabled}
              label={options?.confirmationButtonLabel ? options?.confirmationButtonLabel : t('common.ok')}
              onClick={accept}
            />
          </Box>
        ) : (
          <Button
            disabled={isLoading || !confirmButtonEnabled}
            label={options?.confirmationButtonLabel ? options?.confirmationButtonLabel : t('common.ok')}
            onClick={accept}
          />
        )}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
