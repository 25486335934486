import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import useHttpError from '../../../hooks/useHttpError/useHttpError';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';

import CompanyOnboardingService from '../../../services/CompanyOnboarding/CompanyOnboardingService';
import MarketsService from '../../../services/Markets/MarketsService';
import {
  Company,
  CompanyNotificationTypes,
  CompanyOnboardingRequest,
  CompanyUpdateRequest,
} from '../../../models/CompanyOnboardingModels';

function useCompanyOnboarding() {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const [countryCodesOptions, setCountryCodesOptions] = useState<DropdownItem[]>([]);
  const [marketTypesOptions, setMarketTypesOptions] = useState<DropdownItem[]>([]);
  const notificationTypesOptions = Object.values(CompanyNotificationTypes).map((not) => ({
    key: not,
    value: not,
    label: t(`pages.companyOnboarding.notificationTypes.${not}`),
  }));
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const { getHttpReason } = useHttpError();
  const [companyData, setCompanyData] = useState<Company>({
    companyCode: '',
    bans: [],
    country: '',
    marketType: '',
    market: '',
    id: '',
    name: '',
    planOptions: [],
    distributionListEnabled: false,
    endUserNotificationEnabled: false,
    distributionListEmail: '',
    companyActiveJourneyTypes: CompanyNotificationTypes.PULL_PUSH,
  });
  const { id } = useParams();

  useEffect(() => {
    /**
     * Fetches list of markets and plan options
     */
    const fetchData = async () => {
      setIsLoading(true);
      const marketsResponse = await MarketsService.api.fetchMarkets();
      const countryCodesDropdownOptions = marketsResponse.data.markets
        .filter(
          (market, index, marketsList) =>
            marketsList.findIndex((market2) => market2.countryCode === market.countryCode) === index,
        )
        .map((obj) => ({
          key: obj.countryCode,
          value: obj.countryCode,
          label: `${obj.countryCode}`,
        }));

      setCountryCodesOptions(countryCodesDropdownOptions);

      const marketTypesDropdownOptions = marketsResponse.data.markets
        .filter(
          (market, index, marketsList) =>
            marketsList.findIndex((market2) => market2.marketType === market.marketType) === index,
        )
        .map((obj) => ({
          key: obj.marketType,
          value: obj.marketType,
          label: t(`pages.companyOnboarding.${obj.marketType.toLowerCase()}`),
        }));

      setMarketTypesOptions(marketTypesDropdownOptions);
      if (!id) {
        const planOptionsResponse = await MarketsService.api.fetchPlanOptions();
        setCompanyData((prevState) => ({
          ...prevState,
          planOptions: planOptionsResponse.data.planOptions.map((planOption) => ({ ...planOption, active: true })),
        }));
      }
      setIsLoading(false);
    };

    fetchData().catch(() => {
      snackBar.showSnackBar(t('pages.companyOnboarding.getMarketTypesError'), 'error');
      setIsLoading(false);
    });
  }, [t]);

  useEffect(() => {
    if (id) {
      CompanyOnboardingService.api.fetchCompany(id).then(
        (response) => {
          setCompanyData(response.data);
        },
        () => {
          snackBar.showSnackBar(t('pages.companyOnboarding.fetchCompanyError'), 'error');
        },
      );
    }
  }, [id]);

  /**
   * Creates Company remotely
   * @param params
   */
  const createCompany = (params: CompanyOnboardingRequest) => {
    setIsLoading(true);
    CompanyOnboardingService.api
      .createCompany(params)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.companyOnboarding.companyOnboardingSuccess'), 'success');
          navigate('/companies');
        },
        (res) => {
          const error = getHttpReason(res.response.data, t('pages.companyOnboarding.companyOnboardingError'));
          snackBar.showSnackBar(error, 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Updates a company remotely
   * @param params
   */
  const updateCompany = (params: CompanyUpdateRequest) => {
    setIsLoading(true);
    CompanyOnboardingService.api
      .updateCompany(params)
      .then(
        () => {
          snackBar.showSnackBar(t('translation:pages.companyOnboarding.updateSuccess'), 'success');
          navigate('/companies');
        },
        (res) => {
          const error = getHttpReason(res.response.data, t('pages.companyOnboarding.updateError'));
          snackBar.showSnackBar(error, 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    countryCodesOptions,
    marketTypesOptions,
    createCompany,
    isLoading,
    companyData,
    updateCompany,
    notificationTypesOptions,
  };
}

export default useCompanyOnboarding;
