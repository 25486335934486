/**
 * Model to set up a new Company
 */
import { ApiResponse, PaginatedApiRequest, PaginatedApiResponse } from './ServiceModels';

export enum CompanyNotificationTypes {
  'PULL' = 'PULL',
  'PUSH' = 'PUSH',
  'PULL_PUSH' = 'PULL_PUSH',
}

/**
 * Ban type interface
 */
export interface BillingAccount {
  ban: string;
  name: string;
  ben: string;
}

/**
 * Company request interface
 */
export interface CompanyOnboardingRequest {
  name: string;
  code: string;
  countryCode: string;
  marketCode: string;
  bans: BillingAccount[];
  planOptions: PlanOption[];
  distributionListEmail: string;
  distributionListEnabled: boolean;
  endUserNotificationEnabled: boolean;
  companyActiveJourneyTypes: CompanyNotificationTypes;
}

/**
 * Create company response
 */
export interface CompanyCreateResponse extends ApiResponse {
  [key: string]: string | number;
}

export interface PlanOption {
  label?: string;
  name: string;
  active: boolean;
}

/**
 * Company model
 */
export interface Company {
  id: string;
  name: string;
  companyCode: string;
  country: string;
  marketType: string;
  distributionListEmail: string;
  distributionListEnabled: boolean;
  endUserNotificationEnabled: boolean;
  market: string;
  bans: BillingAccount[];
  planOptions: PlanOption[];
  companyActiveJourneyTypes: '' | CompanyNotificationTypes;
}

/**
 * Paginated companies list
 * Response from API
 */
export interface CompaniesListResponse extends PaginatedApiResponse {
  companies: Company[];
}

/**
 * Companies list request
 * Request params to get the list of companies
 */
export interface CompaniesListRequest extends PaginatedApiRequest {
  filter: string;
}

/**
 * API response for /companies/:id
 */
export interface CompanyResponse extends ApiResponse, Company {}

/**
 * API response for DELETE /companies/:id
 */
export type CompanyDeleteResponse = ApiResponse;

/**
 * API request for /companies/update
 * Used to update a company
 */
export interface CompanyUpdateRequest {
  name: string;
  code: string;
  countryCode: string;
  marketCode: string;
  bans: BillingAccount[];
  companyId: string;
  planOptions: PlanOption[];
  distributionListEmail: string;
  distributionListEnabled: boolean;
  endUserNotificationEnabled: boolean;
  companyActiveJourneyTypes: CompanyNotificationTypes;
}

export interface AuditCompany {
  name: string;
  code: string;
  bans: BillingAccount[];
}

/**
 * API response for audit/companies
 */
export interface AuditCompaniesResponse extends ApiResponse {
  companies: AuditCompany[];
}

/**
 * API response for GET /markets/plan-options
 */
export interface PlanOptionsResponse extends ApiResponse {
  planOptions: PlanOption[];
}

export type NotificationType = 'ADMIN' | 'END_USER' | 'DISTRIBUTION_LIST';

export interface NotificationSetting {
  type: NotificationType;
  enabled: boolean;
  label: string;
}

/**
 * API response for GET /companies/:id/notifications-settings
 */
export interface CompanyNotificationSettingsResponse extends ApiResponse {
  qrCodeNotifications: NotificationSetting[];
}
