import { Container, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import Button from '../../components/Button/Button';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';

function FailedPortalConfigs() {
  const [t] = useTranslation();
  const theme = useTheme();
  const [showPage, setShowPage] = useState(false);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 5);

  useTimer({
    expiryTimestamp,
    onExpire: () => setShowPage(true),
  });

  /**
   * Reload the page
   */
  const reload = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <div>
      {showPage ? (
        <Container maxWidth="md" sx={{ display: 'flex', paddingTop: '4rem' }}>
          <Grid container>
            <Grid
              item
              xl={6}
              xs={12}
              sx={{
                display: 'flex',
                borderRight: {
                  md: `1px solid ${theme.palette.text.disabled}`,
                },
                padding: '2rem',
                flexShrink: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '4rem',
                  textTransform: 'none',
                  lineHeight: 'normal',
                  fontFamily: 'Vodafone RgBd',
                  color: theme.palette.primary.main,
                }}
              >
                {t('pages.errors.failedPortalConfigs.title')}
              </Typography>
            </Grid>
            <Grid
              item
              xl={6}
              md={6}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem', flexShrink: 1 }}
            >
              <Typography sx={{ fontWeight: 'normal', fontSize: '2rem' }}>
                {t('pages.errors.failedPortalConfigs.message1')}
              </Typography>
              <Typography sx={{ fontFamily: 'Vodafone Lt' }}>
                {t('pages.errors.failedPortalConfigs.message2')}
              </Typography>
              <Typography sx={{ fontFamily: 'Vodafone Lt' }}>
                {t('pages.errors.failedPortalConfigs.message3')}
              </Typography>
              <Button color="primary" label={t('common.reload')} onClick={reload} />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

export default FailedPortalConfigs;
