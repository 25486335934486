import { memo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';

function Auth() {
  const { authenticate } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    authenticate().then(() => {
      navigate('/');
    });
  }, []);

  return <LoadingPage />;
}

export default memo(Auth);
