import { AlertColor, Fade, Snackbar as SideToast, useTheme } from '@mui/material';
import React, { createContext, PropsWithChildren, ReactNode, useMemo } from 'react';
import ClickableIcon from '../../components/Icons/ClickableIcon';
import CloseIcon from '../../components/Icons/CloseIcon';
import { InfoReverseIcon } from '../../components/Icons/InfoIcon';
import { SuccessReverseTickIcon } from '../../components/Icons/SuccessTickIcon';
import WarningIcon from '../../components/Icons/WarningIcon';
import SideToastContainer, {
  CloseIconContainer,
  MessageContainer,
  MessageTitle,
  SeverityIconContainer,
  TemplateContainer,
} from './SideToastStyles';

export interface SideToastProps {
  title: string;
  template: ReactNode;
  severity: AlertColor;
  open?: boolean;
  onClose?: () => void;
  id?: string;
}

type SideToastContextActions = {
  showSideToast: (options: SideToastProps) => void;
  closeSideToast: (id: string) => void;
  sideToastState: SideToastProps;
};

export const SideToastContext = createContext({} as SideToastContextActions);

function SideToastProvider({ children }: PropsWithChildren) {
  const initialState = {
    open: false,
    title: '',
    template: null,
    severity: 'error' as AlertColor,
    onClose: () => {},
    id: `${new Date().getTime()}`,
  };
  const [state, setState] = React.useState<SideToastProps>(initialState);
  const { open, title, template, severity, onClose } = state;
  const theme = useTheme();

  /**
   * Show side toast handler
   */
  const showSideToast = (options: SideToastProps) => {
    setState({ open: true, ...options });
  };

  /**
   * Handle close button
   */
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setState(initialState);
  };

  /**
   * Public method to force close the side toast
   * @param id
   */
  const closeSideToast = (id: string) => {
    setState((prevState) => {
      if (id === prevState.id) {
        if (onClose) {
          onClose();
        }
        return initialState;
      }
      return prevState;
    });
  };

  const severityColorMapping = {
    success: theme.palette.success.dark,
    error: theme.palette.state.linkHover,
    info: theme.palette.info.main,
    warning: theme.palette.warning.main,
  };

  const severityIconMapping = {
    success: <SuccessReverseTickIcon />,
    error: <WarningIcon />,
    info: <InfoReverseIcon />,
    warning: <WarningIcon />,
  };

  const value = useMemo(
    () => ({ showSideToast, closeSideToast, sideToastState: state }),
    [state, showSideToast, closeSideToast],
  );

  return (
    <SideToastContext.Provider value={value}>
      <SideToast
        open={open}
        TransitionComponent={Fade}
        transitionDuration={500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          boxShadow: open ? '0px 7.5px 15px 0px rgba(0, 0, 0, 0.15)' : 'none',
          border: open ? `2px solid ${severityColorMapping[severity]}` : 'none',
          borderRadius: '6px',
          width: '27rem',
          minHeight: '6.25rem',
          backgroundColor: open ? 'white' : 'transparent',
          flexDirection: 'column',
        }}
      >
        {open ? (
          <SideToastContainer>
            <SeverityIconContainer backgroundColor={severityColorMapping[severity]}>
              {severityIconMapping[severity]}
            </SeverityIconContainer>
            <MessageContainer>
              {title ? <MessageTitle>{title}</MessageTitle> : null}
              <TemplateContainer>{template}</TemplateContainer>
            </MessageContainer>
            <CloseIconContainer>
              <ClickableIcon onClick={handleClose}>
                <CloseIcon />
              </ClickableIcon>
            </CloseIconContainer>
          </SideToastContainer>
        ) : (
          <div />
        )}
      </SideToast>
      {children}
    </SideToastContext.Provider>
  );
}

export default SideToastProvider;
