import { Box, Divider, RadioGroup, styled, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import Radio from '../../../../../components/Radio/Radio';
import SectionTitle from '../../../../../components/Text/TextStyles';
import UserDetailsContext from '../../../../../contexts/UserDetailsContext/UserDetailsContext';
import { NotificationSetting, NotificationType } from '../../../../../models/CompanyOnboardingModels';
import { RootState } from '../../../../../stores/Store';
import { updateRequest } from '../../../../../stores/UpgradeDeviceStore';

const RowsContainer = styled(Box)(() => ({
  paddingBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const SectionValue = styled(Typography)(() => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
  width: '50%',
  textAlign: 'right',
}));

const SectionValueDisabled = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
  color: theme.palette.text.disabled,
  width: '50%',
  textAlign: 'right',
}));

const SectionLabel = styled(Typography)(() => ({
  lineHeight: 'normal',
  width: '50%',
}));

interface UpgradeDeviceSummaryStepProps {
  nextStep: () => void;
  onClose: () => void;
  isLoading: boolean;
}

function UpgradeDeviceSummaryStep({ nextStep, onClose, isLoading }: UpgradeDeviceSummaryStepProps) {
  const [t] = useTranslation();
  const {
    newDevice,
    deviceToUpgrade,
    upgradeDeviceRequest,
    selectedSimId,
    selectedSimType,
    qrCodeNotificationSettings,
    hasDistributionList,
  } = useSelector((state: RootState) => state.upgradeDeviceReducer);
  const dispatch = useDispatch();
  const user = useContext(UserDetailsContext);

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as NotificationType;
    dispatch(updateRequest({ qrCodeNotification: val }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Summary */}
      <SectionTitle>{t('pages.userDetails.upgradeDeviceDrawer.summary')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel>{t('pages.userDetails.upgradeDeviceDrawer.userName')}</SectionLabel>
          <SectionValue>{`${user?.firstName} ${user?.lastName}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.upgradeDeviceDrawer.activationMethod')}</SectionLabel>
          <SectionValue>
            {t(`pages.userDetails.upgradeDeviceDrawer.${upgradeDeviceRequest.notificationType.toLocaleLowerCase()}`)}
          </SectionValue>
        </Row>
      </RowsContainer>

      {/* From section */}
      <SectionTitle>{t('pages.userDetails.upgradeDeviceDrawer.from')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.userDetails.upgradeDeviceDrawer.device')}</SectionLabel>
          <SectionValueDisabled
            sx={{ width: '75%', textAlign: 'end' }}
          >{`${deviceToUpgrade?.manufacturer} ${deviceToUpgrade?.model}`}</SectionValueDisabled>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>
            {selectedSimType === 'PSIM'
              ? t('pages.userDetails.upgradeDeviceDrawer.iccId')
              : t('pages.userDetails.upgradeDeviceDrawer.eSimId')}
          </SectionLabel>
          <SectionValueDisabled sx={{ width: '75%' }}>{selectedSimId}</SectionValueDisabled>
        </Row>
      </RowsContainer>

      {/* To section */}
      <SectionTitle>{t('pages.userDetails.upgradeDeviceDrawer.to')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.userDetails.upgradeDeviceDrawer.device')}</SectionLabel>
          <SectionValue
            sx={{ width: '75%', textAlign: 'end' }}
          >{`${newDevice?.manufacturer} ${newDevice?.model}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.userDetails.upgradeDeviceDrawer.eSimId')}</SectionLabel>
          <SectionValue sx={{ width: '75%' }}>{newDevice?.simId}</SectionValue>
        </Row>
      </RowsContainer>

      {/* Notification */}
      <SectionTitle sx={{ paddingTop: '1rem' }}>
        {t('pages.userDetails.upgradeDeviceDrawer.notificationSettings')}
      </SectionTitle>
      <Box sx={{ paddingBottom: '1rem' }}>
        <Typography sx={{ paddingBottom: '1.5rem' }}>
          {upgradeDeviceRequest.notificationType === 'PUSH'
            ? t('pages.userDetails.upgradeDeviceDrawer.qrCodeQuestion_push')
            : t('pages.userDetails.upgradeDeviceDrawer.qrCodeQuestion')}
        </Typography>
        <RadioGroup
          aria-labelledby="qr-code-destination"
          name="qr-code-destination"
          value={upgradeDeviceRequest.qrCodeNotification}
          onChange={handleRadioChange}
        >
          {qrCodeNotificationSettings.map((ns: NotificationSetting) => (
            <Radio
              value={ns.type}
              key={ns.type}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {t(`pages.userDetails.upgradeDeviceDrawer.${ns.type}`)}
                  <Typography sx={{ paddingLeft: '1rem', color: (theme) => theme.palette.text.disabled }}>
                    {ns.label}
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </Box>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.monochrome.monoChrome3,
          marginBottom: '1.5rem',
        }}
      />

      <Box sx={{ marginBottom: '1rem' }}>
        <Checkbox
          checked={upgradeDeviceRequest.notifyAdmin}
          onChange={(e) => {
            dispatch(updateRequest({ notifyAdmin: e.currentTarget.checked }));
          }}
          label={t('pages.userDetails.upgradeDeviceDrawer.sendMeNotifications')}
        />
      </Box>

      {hasDistributionList ? (
        <Box sx={{ marginBottom: '2.5rem' }}>
          <Checkbox
            checked={upgradeDeviceRequest.notifyDistributionList}
            onChange={(e) => {
              dispatch(updateRequest({ notifyDistributionList: e.currentTarget.checked }));
            }}
            label={t('pages.userDetails.upgradeDeviceDrawer.sendDLNotifications')}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} disabled={isLoading} />
          <Button label={t('common.send')} color="primaryButton" onClick={nextStep} disabled={isLoading} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default UpgradeDeviceSummaryStep;
