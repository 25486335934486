import { PaginatedApiRequest, PaginatedApiResponse } from './ServiceModels';
import { Device } from './UserDevicesModels';
import { User } from './UsersModels';

/**
 * Types of notification status
 */
export type NotificationStatus = 'ERROR' | 'SUCCESS' | 'INFO';

/**
 * Types of notifications
 */
export enum DeviceJourney {
  LOST_STOLEN_SUSPEND = 'LOST_STOLEN_SUSPEND',
  LOST_STOLEN_RESTORE = 'LOST_STOLEN_RESTORE',
  UPGRADE_PULL = 'UPGRADE_PULL',
  UPGRADE_PUSH = 'UPGRADE_PUSH',
  NEW_CONNECTION_PULL = 'NEW_CONNECTION_PULL',
  NEW_CONNECTION_PUSH = 'NEW_CONNECTION_PUSH',
  TERMINATION_TERMINATION = 'TERMINATION_TERMINATION',
}
export enum NotificationCounter {
  NOTIFICATION_COUNTER = 'NOTIFICATION_COUNTER',
}
export type NotificationType = DeviceJourney | NotificationCounter;

/**
 * Generic type for device journey updates like provisioning, new connections etc
 */
export interface DeviceJourneyData {
  device: Device;
  user: User;
  counter: number;
}

export interface NotificationCounterData {
  counter: number;
}

export type JourneyData = DeviceJourneyData | NotificationCounterData;

/**
 * Live notification response provided by the live notifications websocket
 */
export interface LiveNotificationResponse {
  id: string;
  status: NotificationStatus;
  adminId: string;
  actionId: string;
  read: boolean;
  dateCreated: string;
  type: NotificationType;
  data: JourneyData;
}

/**
 * API request for POST /notifications/read
 */
export interface MarkNotificationAsReadRequest {
  notifications: string[];
}
export interface MarkNotificationAsReadResponse {
  unreadNotifications: number;
}

/**
 * API request for POST /notifications
 */
export type FetchNotificationsRequest = Omit<PaginatedApiRequest, 'sort' | 'sortingType'>;

export interface FetchNotificationsResponse extends PaginatedApiResponse {
  notifications: LiveNotificationResponse[];
  unreadNotifications: number;
}
