import { BoxProps, tooltipClasses, TooltipProps, Tooltip as MTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StatusContainerProps extends BoxProps {
  colors: { bg: string; font: string };
}
const StatusContainer = styled('div')<StatusContainerProps>(({ colors }) => ({
  padding: '0.25rem 0.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  background: colors.bg,
  fontSize: '1rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: colors.font,
  minWidth: '5rem',
  cursor: 'default',
}));

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: 'white',
    minWidth: '8rem',
    fontSize: '1rem',
    lineHeight: theme.typography.pxToRem(18),
    padding: '1rem',
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000',
  },
}));

export default StatusContainer;
