import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/Text/PageTitle';

function TermsAndConditions() {
  const [t] = useTranslation();
  return (
    <Box>
      <PageTitle>{t('translation:pages.termsAndConditions.title')}</PageTitle>
      <Grid container sx={{ paddingTop: '1.5rem', paddingBottom: '2.5rem' }}>
        <Grid item xl={12} md={12} xs={12}>
          <Typography>{t('translation:pages.termsAndConditions.message')}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TermsAndConditions;
