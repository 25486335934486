import * as React from 'react';
import { SVGProps, memo } from 'react';

function EnvelopeIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={28} fill="none" {...props}>
      <path
        stroke={props?.stroke ? props.stroke : '#BD0000'}
        strokeLinecap="round"
        d="m20.763 12.757-6.616 6.817a1.864 1.864 0 0 1-2.635.058l-.058-.058-6.434-6.818"
      />
      <path stroke="#BD0000" strokeLinecap="round" d="m4.645 13.07 6.993-6.818a1.864 1.864 0 0 1 2.693 0l6.807 6.818" />
      <path
        stroke={props?.stroke ? props.stroke : '#BD0000'}
        strokeLinecap="round"
        d="M5.02 12.756c-.537.19-.625.943-.625 1.308v7.5a2 2 0 0 0 2 2h12.993a2 2 0 0 0 2-2v-7.5c0-.365-.094-1.125-.625-1.308"
      />
    </svg>
  );
}
const Memo = memo(EnvelopeIcon);
export default Memo;
