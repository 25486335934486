import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationSetting } from '../models/CompanyOnboardingModels';
import { Device, Sim, SimStatus, UpgradeDeviceRequest } from '../models/UserDevicesModels';

interface UpgradeDeviceStateData {
  upgradeDeviceRequest: UpgradeDeviceRequest;
  newDevice: Device | null;
  deviceToUpgrade: Device | null;
  simStatus: SimStatus;
  typeOfConnection: '' | 'ESIM' | 'DEVICE';
  selectedSimId: string;
  selectedSimType: '' | 'ESIM' | 'PSIM';
  qrCodeNotificationSettings: NotificationSetting[];
  hasDistributionList: boolean;
}

const initialState: UpgradeDeviceStateData = {
  upgradeDeviceRequest: {
    notifyAdmin: true,
    journey: 'UPGRADE',
    eid: '',
    userId: '',
    notificationType: '',
    phoneNumber: '',
    qrCodeNotification: 'ADMIN',
    notifyDistributionList: false,
  },
  simStatus: SimStatus.CONNECTED,
  newDevice: null,
  deviceToUpgrade: null,
  typeOfConnection: 'DEVICE',
  selectedSimId: '',
  selectedSimType: '',
  qrCodeNotificationSettings: [],
  hasDistributionList: false,
};

export const UpgradeDeviceSlice = createSlice({
  name: 'upgradeDevice',
  initialState,
  reducers: {
    setNewDevice: (state, action: PayloadAction<Device>) => {
      state.newDevice = action.payload;
    },
    setDeviceToUpgrade: (state, action: PayloadAction<{ device: Device; sim: Sim }>) => {
      state.deviceToUpgrade = action.payload.device;
      state.selectedSimId = action.payload.sim.simId;
      state.selectedSimType = action.payload.sim.type as 'PSIM' | 'ESIM' | '';
      state.simStatus = action.payload.sim.status;
      state.upgradeDeviceRequest = { ...state.upgradeDeviceRequest, phoneNumber: action.payload.sim.phoneNumber };
    },
    updateTypeOfConnection: (state, action: PayloadAction<'' | 'ESIM' | 'DEVICE'>) => {
      state.upgradeDeviceRequest.eid = '';
      state.typeOfConnection = action.payload;
    },
    updateRequest: (state, action: PayloadAction<Partial<UpgradeDeviceRequest>>) => {
      state.upgradeDeviceRequest = {
        ...state.upgradeDeviceRequest,
        ...action.payload,
      };
    },
    setNotificationSettings: (state, action: PayloadAction<NotificationSetting[]>) => {
      state.hasDistributionList = action.payload.some((setting) => setting.type === 'DISTRIBUTION_LIST');
      if (action.payload.some((setting) => setting.type === 'END_USER'))
        state.upgradeDeviceRequest.qrCodeNotification = 'END_USER';
      state.qrCodeNotificationSettings = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  updateRequest,
  setNewDevice,
  setDeviceToUpgrade,
  reset,
  updateTypeOfConnection,
  setNotificationSettings,
} = UpgradeDeviceSlice.actions;

export default UpgradeDeviceSlice.reducer;
