import { Skeleton } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import PageTitle from '../../../../components/Text/PageTitle';
import UserDetailsContext from '../../../../contexts/UserDetailsContext/UserDetailsContext';
import { GetDeviceFn } from '../../../../models/UserDevicesModels';
import UserDetailsSimsTable from '../components/UserDetailsSimsTable';
import useUserDetails from '../useUserDetails';
import DeviceDetailsInfoRows from './DeviceDetailsInfoRows';
import useDeviceDetails from './useDeviceDetails';

function DeviceDetails() {
  const { id, deviceId } = useParams();
  const { user } = useUserDetails(id);
  const { device, sims, fetchDeviceDetails, updateDeviceDetails, resendNotification, isLoading } =
    useDeviceDetails(deviceId);

  /**
   * Returns the current device
   */
  const getDevice: GetDeviceFn = () => device;

  return (
    <UserDetailsContext.Provider value={user}>
      <Breadcrumbs
        activeLinks={[
          { label: user ? `${user?.firstName} ${user?.lastName}` : '', url: `/esimManager/userDetails/${id}` },
        ]}
        disabledLinks={[device ? `${device?.manufacturer} ${device?.model}` : '']}
      />

      <PageTitle>{device ? `${device?.manufacturer} ${device?.model}` : <Skeleton width="25%" />}</PageTitle>
      <DeviceDetailsInfoRows device={device} isLoading={isLoading} updateDeviceDetails={updateDeviceDetails} />
      <UserDetailsSimsTable
        sims={sims}
        isLoading={isLoading}
        showTotalSims={false}
        isPerformingAction={isLoading}
        resendNotification={resendNotification}
        getDeviceById={getDevice}
        onNewConnectionSuccess={fetchDeviceDetails}
        onUpgradeSuccess={fetchDeviceDetails}
        onSuspendSimSuccess={fetchDeviceDetails}
        onRestoreConnectionSuccess={fetchDeviceDetails}
        onTerminateConnectionSuccess={fetchDeviceDetails}
      />
    </UserDetailsContext.Provider>
  );
}

export default DeviceDetails;
