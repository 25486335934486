import * as React from 'react';
import { SVGProps, memo } from 'react';

function SuccessTickIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
      <rect width={24} height={24} x={0.5} fill="#428600" fillOpacity={0.1} rx={12} />
      <path
        stroke="#428600"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m16.54 9.008-5.523 5.523-2.454-2.454"
      />
    </svg>
  );
}

export function SuccessReverseTickIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={21} fill="none" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11 .25C5.34.25.75 4.84.75 10.5S5.34 20.75 11 20.75c5.661 0 10.25-4.59 10.25-10.25S16.661.25 11 .25Zm6.52 6.79a.75.75 0 1 0-1.04-1.08l-7.788 7.499-3.172-3.055a.75.75 0 0 0-1.04 1.08l4.212 4.057 8.828-8.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function SuccessTickIcon2x(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
      <rect width={24} height={24} x={0.5} fill="#428600" fillOpacity={0.1} rx={12} />
      <path
        stroke="#428600"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m16.54 9.008-5.523 5.523-2.454-2.454"
      />
    </svg>
  );
}

export default memo(SuccessTickIcon);
