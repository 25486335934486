import { Box, Divider, FormControl, Grid, useTheme } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import NotificationErrorIcon from '../../../../components/Icons/NotificationErrorIcon';
import UploadIcon from '../../../../components/Icons/UploadIcon';
import TextInput from '../../../../components/TextInput/TextInput';
import { FeedbackWrapper, InputLabelWrapper } from '../../../../components/TextInput/TextInputStyle';
import usePortalConfig from '../../../../hooks/usePortalConfig/usePortalConfig';
import useRolePermissions from '../../../../hooks/useRolePermissions/useRolePermissions';
import { DynamicObject } from '../../../../models/CommonModels';
import { UemCreateRequest } from '../../../../models/UemOnboardingModels';
import useUemOnboarding from '../useUemOnboarding';

function UemOnboardingForm() {
  const [t] = useTranslation();
  const { isUserAllowed } = useRolePermissions();
  const [companyId, setCompanyId] = useState('');
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { routePermissions } = usePortalConfig();

  const {
    uemTypeOptions,
    companyOptions,
    authenticationOptions,
    fields,
    authentication,
    setAuthentication,
    selectedType,
    setSelectedType,
    createUem,
    updateUem,
    uemData,
    editableFields,
    isLoading,
  } = useUemOnboarding(id);

  /**
   * Gets the formik validation schema
   */
  const getValidationSchema = () => {
    const dynamicObject: DynamicObject<yup.Schema> = {};
    dynamicObject.type = yup.string().required(t('common.inputValidations.requiredField'));
    dynamicObject.companyId = yup.string().required(t('common.inputValidations.requiredField'));
    dynamicObject.authentication = yup.string().required(t('common.inputValidations.requiredField'));
    fields.forEach((field) => {
      if (id) {
        if (editableFields && editableFields.includes(field)) {
          dynamicObject[field] = yup.string().required(t('common.inputValidations.requiredField'));
        } else {
          dynamicObject[field] = yup.string();
        }
      } else {
        dynamicObject[field] = yup.string().required(t('common.inputValidations.requiredField'));
      }
    });
    return yup.object(dynamicObject);
  };

  /**
   * Gets the initial formik values
   * Based on the selected uem type selected
   */
  const getInitialValues = useMemo(() => {
    const dynamicObject: DynamicObject<string> = {};
    if (id && uemData) {
      fields.forEach((field) => {
        dynamicObject[field] = uemData[field] as string;
      });
      dynamicObject.type = uemData.type;
      dynamicObject.authentication = uemData.authentication;
      dynamicObject.companyId = uemData.companyId;
    } else {
      fields.forEach((field) => {
        dynamicObject[field] = '';
      });
      dynamicObject.type = selectedType;
      dynamicObject.authentication = authentication;
      dynamicObject.companyId = companyId;
    }
    return dynamicObject;
  }, [selectedType, fields, authentication, uemData]);

  /**
   * Resets the form
   * @param form
   * NOTE: https://jira.tools.aws.vodafone.com/browse/DVMF-16994
   * This behaviour is not ideal but vdf wants it to be like this
   */
  const resetForm = (form: FormikProps<any>) => {
    form.resetForm({
      values: {
        ...getInitialValues,
        type: '',
        companyId: '',
        authentication: '',
      },
    });
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setCompanyId('');
    setSelectedType('');
    setAuthentication('');
  };

  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    onSubmit: (values) => {
      const data: UemCreateRequest = {
        uemCredentialsRequest: {
          companyId: values.companyId,
          type: values.type,
          authentication: values.authentication,
          ...values,
        },
      };
      if (values.authentication === 'CERTIFICATE' && file) {
        data.certificate = file;
      }
      if (id) {
        data.uemCredentialsRequest.id = id;
        updateUem(data);
      } else {
        createUem(data, () => resetForm(formik));
      }
    },
  });

  /**
   * Simulates the click on the hidden input by clicking on the displayed button
   */
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  /**
   * Removes the selected file
   */
  const removeFile = () => {
    setFile(null);
    formik.setFieldValue('certificate', '');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  /**
   * Handles file change
   * @param e
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched('certificate', true);
    formik.setFieldValue('certificate', '');
    if (!e.target.files) {
      formik.setFieldError('certificate', t('common.inputValidations.requiredField'));
      return;
    }
    if (e.target.files[0].size > 4 * 1000000) {
      removeFile();
      formik.setFieldError('certificate', t('common.inputValidations.maxSize', { val: '4mb' }));
      return;
    }
    formik.setFieldError('certificate', undefined);
    formik.setFieldValue('certificate', e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  /**
   * Renders the dynamic form input
   * either certificate or plain text field
   * @param field
   */
  const renderFormInput = (field: string) => {
    if (field !== 'certificate') {
      return (
        <TextInput
          autoComplete="off"
          key={field}
          id={field}
          name={field}
          type={field === 'password' || field === 'certificatePassword' ? 'password' : 'text'}
          label={t(`pages.uemOnboarding.${field}`)}
          value={formik.values[field]}
          onInputChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[field] && Boolean(formik.errors[field])}
          feedback={formik.touched[field] && Boolean(formik.errors[field]) ? formik.errors[field] : ''}
          formStyle={{ paddingBottom: '1.5rem' }}
          disabled={!!id && !editableFields.includes(field)}
          placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.uemOnboarding.${field}`)}
        />
      );
    }
    if (field === 'certificate') {
      return (
        <FormControl sx={{ width: '100%', paddingBottom: '1.5rem' }}>
          <InputLabelWrapper shrink>{t('pages.uemOnboarding.certificate')}</InputLabelWrapper>
          <Button
            onClick={handleUploadClick}
            color="secondaryButton"
            label={
              file ? (
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  {file.name}
                  <CloseIcon
                    stroke="#fff"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFile();
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <UploadIcon />
                  {t('pages.uemOnboarding.uploadCertificate')}
                </Box>
              )
            }
            sx={{ width: '100%' }}
          />
          {formik.touched.certificate && formik.errors.certificate ? (
            <FeedbackWrapper error component="span">
              <Box sx={{ paddingRight: '0.25rem' }}>
                <NotificationErrorIcon />
              </Box>
              {formik.errors.certificate}
            </FeedbackWrapper>
          ) : null}
          <input
            type="file"
            ref={inputRef}
            accept=".crt, .pem, .txt, .der, .cer, .pfx, .p12"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </FormControl>
      );
    }
    return null;
  };

  return (
    <Grid container>
      <Grid item xl={4} md={6} xs={12} sx={{ paddingBottom: '2rem' }}>
        <form onSubmit={formik.handleSubmit} style={{ paddingTop: '1.5rem' }}>
          <Dropdown
            id="type"
            name="type"
            label={t('pages.uemOnboarding.uemType')}
            value={formik.values.type}
            onChange={(e) => {
              formik.handleChange(e);
              setSelectedType(e.target.value as string);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.type && Boolean(formik.errors.type)}
            feedback={formik.touched.type && Boolean(formik.errors.type) ? formik.errors.type : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            items={uemTypeOptions}
            disabled={!!id}
            showLoading={!!id && isLoading}
            placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.uemOnboarding.uemType')}
          />

          <Dropdown
            id="companyId"
            name="companyId"
            label={t('pages.uemOnboarding.company')}
            value={formik.values.companyId}
            onChange={(e) => {
              formik.handleChange(e);
              setCompanyId(e.target.value as string);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.companyId && Boolean(formik.errors.companyId)}
            feedback={formik.touched.companyId && Boolean(formik.errors.companyId) ? formik.errors.companyId : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            items={companyOptions}
            disabled={!isUserAllowed(routePermissions.uemCreate) || !!id}
            showLoading={!!id && isLoading}
            placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.uemOnboarding.company')}
          />

          {authenticationOptions.length ? (
            <Dropdown
              id="authentication"
              name="authentication"
              label={t('pages.uemOnboarding.authenticationType')}
              value={formik.values.authentication}
              onChange={(e) => {
                formik.handleChange(e);
                setAuthentication(e.target.value as string);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.authentication && Boolean(formik.errors.authentication)}
              feedback={
                formik.touched.authentication && Boolean(formik.errors.authentication)
                  ? formik.errors.authentication
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              items={authenticationOptions}
              disabled={!isUserAllowed(routePermissions.uemCreate) || !!id}
              placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.uemOnboarding.authenticationType')}
            />
          ) : null}

          {fields.length ? (
            <Divider
              sx={{ borderColor: theme.palette.monochrome.monoChrome3, marginTop: '1rem', marginBottom: '2.5rem' }}
            />
          ) : null}

          {fields.map((f) => renderFormInput(f))}
          {id ? (
            <ButtonGroup sx={{ paddingTop: '0.5rem' }}>
              <Button
                label={t('common.cancel')}
                color="alternative2Button"
                type="submit"
                onClick={() => navigate('/uemOnboarding')}
              />
              <Button label={t('common.saveChanges')} color="primaryButton" type="submit" disabled={isLoading} />
            </ButtonGroup>
          ) : (
            <ButtonGroup sx={{ paddingTop: '0.5rem' }}>
              {isUserAllowed(routePermissions.uemCreate) ? (
                <Button
                  label={t('pages.uemOnboarding.submit')}
                  color="primaryButton"
                  type="submit"
                  disabled={isLoading}
                />
              ) : (
                <Button label={t('pages.uemOnboarding.submit')} color="primaryButton" type="submit" disabled />
              )}
            </ButtonGroup>
          )}
        </form>
      </Grid>
    </Grid>
  );
}

export default UemOnboardingForm;
