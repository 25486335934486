import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import useAuth from './hooks/useAuth/useAuth';
import usePortalConfig from './hooks/usePortalConfig/usePortalConfig';
import AdminOnboarding from './pages/Admins/AdminOnboarding/AdminOnboarding';
import Admins from './pages/Admins/Admins';
import AuditTrail from './pages/AuditTrail/AuditTrail';
import Auth from './pages/Auth/Auth';
import AuthenticatedRoute from './pages/AuthenticatedRoute/AuthenticatedRoute';
import Companies from './pages/Companies/Companies';
import CompanyOnboarding from './pages/Companies/CompanyOnboarding/CompanyOnboarding';
import Dashboard from './pages/Dashboard/Dashboard';
import DevicesReport from './pages/DevicesReport/DevicesReport';
import ErrorRoute from './pages/Errors/ErrorRoute';
import NotAllowedRoute from './pages/Errors/NotAllowedRoute';
import NotFoundRoute from './pages/Errors/NotFoundRoute';

import ESimManager from './pages/ESimManager/ESimManager';
import DeviceDetails from './pages/ESimManager/UserDetails/DeviceDetails/DeviceDetails';
import UserDetails from './pages/ESimManager/UserDetails/UserDetails';
import Import from './pages/Import/Import';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import PublicRoute from './pages/PublicRoute/PublicRoute';
import SessionExpired from './pages/SessionExpired/SessionExpired';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import UemOnboarding from './pages/Uems/UemOnboarding/UemOnboarding';
import Uems from './pages/Uems/Uems';

function Router() {
  const { authenticate } = useAuth();
  const { routePermissions } = usePortalConfig();

  const browserRoutes = [
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    /* Insert here the routes without authentication
     (the list of routes without auth, also need to be
     updated on the AuthContext) */
    {
      path: 'privacypolicy',
      element: (
        <PublicRoute>
          <PrivacyPolicy />
        </PublicRoute>
      ),
    },
    {
      path: 'termsandconditions',
      element: (
        <PublicRoute>
          <TermsAndConditions />
        </PublicRoute>
      ),
    },
    {
      path: '/notAllowed',
      element: <NotAllowedRoute />,
    },
    {
      path: '/404',
      element: <NotFoundRoute />,
    },
    {
      path: '/auth',
      element: <Auth />,
    },
    {
      path: '/sessionExpired',
      element: <SessionExpired />,
    },

    /* end */
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: '/',
      errorElement: <ErrorRoute />,
      children: [
        {
          loader: () => authenticate(true, 'dashboard'),
          path: 'dashboard',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.dashboard}>
              <Dashboard />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'dashboard/auditTrail'),
          path: 'dashboard/auditTrail',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.auditTrail}>
              <AuditTrail />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'dashboard/devicesReport'),
          path: 'dashboard/devicesReport',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.devicesReport}>
              <DevicesReport />
            </AuthenticatedRoute>
          ),
        },
        /* eSIM manager */
        {
          loader: () => authenticate(true, 'esimManager'),
          path: 'esimManager',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.eSimManager}>
              <ESimManager />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'esimManager/userDetails/:id'),
          path: 'esimManager/userDetails/:id',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.eSimManager}>
              <UserDetails />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'esimManager/userDetails/:id/deviceDetails/:deviceId'),
          path: 'esimManager/userDetails/:id/deviceDetails/:deviceId',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.eSimManager}>
              <DeviceDetails />
            </AuthenticatedRoute>
          ),
        },
        /* UEM onboarding */
        {
          loader: () => authenticate(true, 'uemOnboarding'),
          path: 'uemOnboarding',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.uemList}>
              <Uems />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'uemOnboarding/create'),
          path: 'uemOnboarding/create',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.uemCreate}>
              <UemOnboarding />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'uemOnboarding/edit/:id'),
          path: 'uemOnboarding/edit/:id',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.uemCreate}>
              <UemOnboarding />
            </AuthenticatedRoute>
          ),
        },
        /* Company onboarding */
        {
          loader: () => authenticate(true, 'companies'),
          path: 'companies',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.companiesList}>
              <Companies />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'companies/create'),
          path: 'companies/create',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.companiesCreate}>
              <CompanyOnboarding />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'companies/edit/:id'),
          path: 'companies/edit/:id',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.companiesList}>
              <CompanyOnboarding />
            </AuthenticatedRoute>
          ),
        },
        /* Admins */
        {
          loader: () => authenticate(true, 'admins'),
          path: 'admins',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.adminsList}>
              <Admins />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'admins/create'),
          path: 'admins/create',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.adminsCreate}>
              <AdminOnboarding />
            </AuthenticatedRoute>
          ),
        },
        {
          loader: () => authenticate(true, 'admins/edit/:id'),
          path: 'admins/edit/:id',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.adminsCreate}>
              <AdminOnboarding />
            </AuthenticatedRoute>
          ),
        },
        /* Import */
        {
          loader: () => authenticate(true, 'import'),
          path: 'import',
          element: (
            <AuthenticatedRoute allowedRoles={routePermissions.import}>
              <Import />
            </AuthenticatedRoute>
          ),
        },
      ],
    },
  ];

  const router = createBrowserRouter(browserRoutes);

  return <RouterProvider router={router} />;
}

export default Router;
