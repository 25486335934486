import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import LiveNotificationsService from '../../../services/LiveNotifications/LiveNotificationsService';
import {
  setNotifications,
  setNotificationsCounter,
  markNotificationAsRead as markAsRead,
} from '../../../stores/NotificationsCenterStore';
import { RootState } from '../../../stores/Store';

function useNotificationsDrawer() {
  const PAGE_SIZE = 10;
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const { drawerOpen, notifications } = useSelector((state: RootState) => state.notificationsCenterReducer);

  const hasMoreNotifications = useMemo(
    () => notifications.length < totalNotifications,
    [notifications.length, totalNotifications],
  );

  /**
   * Fetch the list of notifications remotely
   */
  const fetchNotifications = () => {
    setIsLoading(true);
    LiveNotificationsService.api
      .fetchNotifications({ page: activePage, pageSize: PAGE_SIZE })
      .then(
        (res) => {
          dispatch(setNotifications([...notifications, ...res.data.notifications]));
          dispatch(setNotificationsCounter(res.data.unreadNotifications));
          setTotalNotifications(res.data.totalElements);
        },
        () => {
          snackBar.showSnackBar(t('notificationsCenter.fetchNotificationsError'), 'error');
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Marks one or all notifications as read
   * @param id
   */
  const markNotificationAsRead = (id?: string) => {
    setIsLoading(true);
    const nIds = id ? [id] : [];
    LiveNotificationsService.api
      .markNotificationAsRead({ notifications: nIds })
      .then(
        (res) => {
          dispatch(markAsRead(id));
          dispatch(setNotificationsCounter(res.data.unreadNotifications));
        },
        () => {
          snackBar.showSnackBar(t('notificationsCenter.markAsReadError'), 'error');
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Selects the next page of notifications
   */
  const setNextPage = () => {
    setActivePage((prev) => prev + 1);
  };

  useEffect(() => {
    if (drawerOpen) {
      fetchNotifications();
    } else if (!drawerOpen) {
      dispatch(setNotifications([]));
      setActivePage(0);
    }
  }, [activePage, drawerOpen]);

  return { setNextPage, markNotificationAsRead, isLoading, hasMoreNotifications, notifications, drawerOpen };
}

export default useNotificationsDrawer;
