import * as React from 'react';
import { memo, SVGProps } from 'react';

function InfoIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
      <path
        fill={props?.fill ? props?.fill : '#005EA5'}
        d="M8.977 1.523a7.5 7.5 0 1 0 7.5 7.5 7.509 7.509 0 0 0-7.5-7.5Zm-.165 3.704a.469.469 0 1 1 0 .937.469.469 0 0 1 0-.937Zm.938 7.5h-1.5a.375.375 0 1 1 0-.75h.375v-3.75H8.25a.375.375 0 0 1 0-.75H9a.375.375 0 0 1 .375.375v4.125h.375a.375.375 0 0 1 0 .75Z"
      />
    </svg>
  );
}

export function InfoReverseIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path fill="#fff" fillRule="evenodd" d="M9.969.031a9.5 9.5 0 1 1 0 19 9.5 9.5 0 0 1 0-19Z" clipRule="evenodd" />
      <circle cx={9.75} cy={5.094} r={0.623} fill="#005EA5" />
      <path stroke="#005EA5" strokeLinecap="round" strokeLinejoin="round" d="M10 13.781V7.97H9M9 13.969h2" />
    </svg>
  );
}
export default memo(InfoIcon);
